@font-face {
	font-family: 'ace';
	src: url('./assets/AceSerif.ttf') format("truetype");
}  

fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
  font-size: 100%;
	font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
  background:	#C3B091
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	font-family: "ace";
}
a:link {
	text-decoration: inherit;
	color: inherit;
	cursor: auto;
}

a:visited {
	text-decoration: inherit;
	color: inherit;
	cursor: auto;
}

a:hover {
	color: #964000;
}

h2 {
	font-family: "ace";
}
h2 p {
  font-family: 'Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif; ';
}