.careersShell {
  min-height: 93.7vh;
}

.innerCareersShell {
  padding-top: 65px;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.careersImageBox {
  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.careersTextBox {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.careersTextBox p {
  text-indent: 5%;
}

.careersTextBox div {
  width: 50%;
  padding: 10px;
}

.careersTextBox div:first-child {
  border-right: 3px solid rgb(145, 109, 43);
}

.careerBox {
  max-width: 750px;
  width: 75%;
  height: 100%;
  background: whitesmoke;
  margin: 50px auto;
  box-shadow: 4px 4px 5px #222;
  padding: 40px;
}

.careersImage {
  background-image: url("../../assets/scholar.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  width: 750px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.careersEmail {
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.colorStrikeCareersTop {
  background: rgb(145, 109, 43);
  height: 1600px;
  width: 200px;
  position: absolute;
  transform: rotate(-45deg);
  top: -500px;
  right: -100px;
  z-index: -2;
}

.colorStrikeCareersBottom {
  background: rgb(145, 109, 43);
  height: 1600px;
  width: 200px;
  position: absolute;
  transform: rotate(-45deg);
  bottom: -500px;
  left: -100px;
  z-index: -2;
}

@media (max-width: 600px) {
    .careersTextBox {
        flex-direction: column;
    }

    .careersTextBox div {
        width: 100%;
    }

    .careersTextBox div:first-child {
        border-right: none;
        align-items: center;
        border-bottom: 3px solid rgb(145, 109, 43);
    }

    .careerBox {
        width: 100%;
        margin: 25px auto;
        padding: 0;
      }
}
