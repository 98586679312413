@import url(https://fonts.googleapis.com/css?family=MedievalSharp&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
	font-family: 'ace';
	src: url(/static/media/AceSerif.76365ca1.ttf) format("truetype");
}  

fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
  font-size: 100%;
	font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
  background:	#C3B091
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	font-family: "ace";
}
a:link {
	text-decoration: inherit;
	color: inherit;
	cursor: auto;
}

a:visited {
	text-decoration: inherit;
	color: inherit;
	cursor: auto;
}

a:hover {
	color: #964000;
}

h2 {
	font-family: "ace";
}
h2 p {
  font-family: 'Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif; ';
}
h1 {
  color: whitesmoke;
  font-family: "MedievalSharp";
  font-size: 35px;
}

.shell {
  overflow: hidden;
}

.frontPage {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.59);
  overflow: hidden;
}

.background {
  position: absolute;
  width: 100vw;
  z-index: -2;
  top: -6%;
}

.tint {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.titleCard {
  border: 5px solid whitesmoke;
  height: 100px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

button {
  position: absolute;
  padding: 7px 25px;
  bottom: -50px;
  background: whitesmoke;
  font-size: 19px;
  border: none;
  box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.59);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.bonfireButton:hover {
  background: #964000;
  color: whitesmoke;
}

.bonfireButton a:hover {
  color: whitesmoke;
}

.discordButton {
  position: inherit;
  padding: 10px;
  font-size: 18px;
  border: none;
  box-shadow: 5px 5px 7px 0px rgba(0, 0, 0, 0.59);
  background: #4a6496;
  color: whitesmoke;
}

.discordButton:hover {
  background: #222;
  color: whitesmoke;
}

.discordButton a:hover {
  color: whitesmoke;
  height: 500px;
  width: 100%;
}

.discordSquare {
  height: 414px;
  width: 75%;
  min-width: 650px;
  max-width: 950px;
  margin: 75px auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  background: whitesmoke;
  box-shadow: 4px 4px 5px #222;
}

.discordShell {
  overflow: hidden;
  position: relative;
}

.colorStrike {
  background: #4a6496;
  height: 1500px;
  width: 500px;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: -500px;
  right: 150px;
  z-index: -3;
}

.discordIcon {
  position: absolute;
  background-image: url(/static/media/discordIcon.212244ed.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  top: -30px;
  right: -37px;
  height: 75px;
  width: 75px;
  z-index: 0;
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg);
}

.textBox {
  width: 20%;
  padding: 50px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.imageBox {
  height: 414px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.imageOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(whitesmoke),
    color-stop(rgba(195, 176, 145, 0)),
    color-stop(rgba(195, 176, 145, 0)),
    to(rgba(195, 176, 145, 0))
  );
  background: linear-gradient(
    to left,
    whitesmoke,
    rgba(195, 176, 145, 0),
    rgba(195, 176, 145, 0),
    rgba(195, 176, 145, 0)
  );
  z-index: 1;
}

.discordImage {
  background-image: url(/static/media/cossacks.0d2a423c.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  width: 750px;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media (max-width: 1000px) {
  .background {
    width: 1000px;
    top: unset;
    height: 100vh;
  }
}

@media (max-width: 600px) {
  .background {
    top: 65px;
  }

  .discordSquare {
    margin: 10px 0 0 0;
    display: inherit;
    position: relative;
  }

  .imageBox {
    width: 100%;
  }

  .imageOverlay {
    display: none;
  }

  .textBox {
    position: absolute;
    top: 0;
    color: whitesmoke;
    width: 30%;
    background: -webkit-gradient(linear, left top, right top, from(#c3b091), color-stop(rgba(195, 176, 145, 0.5)), to(rgba(195, 176, 145, 0)));
    background: linear-gradient(to right, #c3b091, rgba(195, 176, 145, 0.5), rgba(195, 176, 145, 0));
    padding: 50px;
    height: 100%;
    justify-content: flex-start;
  }

  h2 {
    font: 45px;
  }

  .discordIcon {
    position: absolute;
    top: 250px;
    left: 85px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    z-index: 1;
  }

  .discordButton {
    position: absolute;
    top: 300px;
    left: 50px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    z-index: 1;
    height: 50px;
  }

  .colorStrike {
    display: none;
  }
}

.aboutShell p {
    text-indent: 5%;
}

.aboutShell {
    min-height: 93.7vh;
}

.innerShell {
  padding-top: 65px;
  min-height: 86.4vh;
  display: flex;
  overflow: hidden;
  position: relative;
}

.box {
  height: 500px;
  width: 35%;
  background: whitesmoke;
  margin: 50px auto;
  box-shadow: 4px 4px 5px #222;
  padding: 50px;
}

.box h2 {
    color: #964000
}

.box a {
    font-weight: 700;
    color: #964000;    
    font-size: 15px;
}

.box a:hover {
    font-weight: 700;
    color: black;
}

.quote {
    font-style: italic;
    color: #964000;
}

.colorStrikeAboutTop {
  background: #964000;
  height: 1600px;
  width: 250px;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: -500px;
  left: 50px;
  z-index: -2;
}

.colorStrikeAboutMiddle {
  background: #7e3600;
  height: 1600px;
  width: 250px;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: -500px;
  left: 275px;
  z-index: -2;
}

.colorStrikeAboutBottom {
  background: #5f2900;
  height: 1600px;
  width: 100px;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: -500px;
  left: 455px;
  z-index: -2;
}

@media (max-width: 800px) {
    .innerShell {
        flex-direction: column;
      }

      .box {
          width: 75%;
          margin: 5% auto;
      }

  }

  @media (max-width: 600px) { 
    .box {
        width: 100%;
        height: 100%;
        margin: 15px 0;
        padding: 0;
    }

    .aboutShell p {
        text-indent: 5%;
        margin: 15px
    }
  }
  
.careersShell {
  min-height: 93.7vh;
}

.innerCareersShell {
  padding-top: 65px;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.careersImageBox {
  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.careersTextBox {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.careersTextBox p {
  text-indent: 5%;
}

.careersTextBox div {
  width: 50%;
  padding: 10px;
}

.careersTextBox div:first-child {
  border-right: 3px solid rgb(145, 109, 43);
}

.careerBox {
  max-width: 750px;
  width: 75%;
  height: 100%;
  background: whitesmoke;
  margin: 50px auto;
  box-shadow: 4px 4px 5px #222;
  padding: 40px;
}

.careersImage {
  background-image: url(/static/media/scholar.66c6837f.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  width: 750px;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.careersEmail {
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.colorStrikeCareersTop {
  background: rgb(145, 109, 43);
  height: 1600px;
  width: 200px;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: -500px;
  right: -100px;
  z-index: -2;
}

.colorStrikeCareersBottom {
  background: rgb(145, 109, 43);
  height: 1600px;
  width: 200px;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  bottom: -500px;
  left: -100px;
  z-index: -2;
}

@media (max-width: 600px) {
    .careersTextBox {
        flex-direction: column;
    }

    .careersTextBox div {
        width: 100%;
    }

    .careersTextBox div:first-child {
        border-right: none;
        align-items: center;
        border-bottom: 3px solid rgb(145, 109, 43);
    }

    .careerBox {
        width: 100%;
        margin: 25px auto;
        padding: 0;
      }
}

.logo {
  background-image: url(/static/media/logo.31c29bdc.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 58px;
  width: 126px;
}

.nav {
  background: whitesmoke;
  width: 100%;
  position: fixed;
  z-index: 2;
  border-bottom: 5px solid #964000;
  box-shadow: -1px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.subNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.expandedNav {
  display: flex;
  width: 35%;
  justify-content: space-between;
  align-items: center;
}

.footer {
  background: whitesmoke;
  width: 100%;
  border-top: 3px solid #964000;
  z-index: 1;
}

.footer div {
  background: whitesmoke;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

.footer div p:hover {
  color: #964000;
}

.hamNav {
  display: none;
  height: 36px;
  width: 36px;
  position: relative;
  -webkit-transition: .6s;
  transition: .6s;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.leftArrow {
  height: 25px;
  width: 10px;
  background: #964000;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  left: 8px;
  top: 5px;
}

.rightArrow {
  height: 25px;
  width: 10px;
  background: #964000;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  right: 8px;
  top: 5px;
}

.rotated {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg)
}

.dropdownNav {
  flex-direction: column;
  align-items: center;
  position: absolute;
  background: whitesmoke;
  top: 65px;
  right: 0;
  padding: 0 25px;
  height: 0px;
  overflow: hidden;
  -webkit-transition: .6s;
  transition: .6s;
  display: none;
}

.open {
  height: 165px;
  border-bottom: 5px solid #964000;
  padding: 25px;
}

.dropdownNav p {
  margin: 0;
}

.dropdownNav a {
  margin: 15px 0;
  font-size: 25px;
}

.navOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.48);
  z-index: 1;
  -webkit-transition: .6s;
  transition: .6s
}

.openOver {
  height: 100%;
}

@media (max-width: 825px) {
  .footer div {
    width: 100%;
  }

  .expandedNav {
    display: none;
  }

  .hamNav {
    display: flex;
  }

  .dropdownNav {
    display: flex;
  }

  .navOverlay { 
    display: inherit
  }
}

