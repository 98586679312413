.logo {
  background-image: url("../../assets/logo.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 58px;
  width: 126px;
}

.nav {
  background: whitesmoke;
  width: 100%;
  position: fixed;
  z-index: 2;
  border-bottom: 5px solid #964000;
  -webkit-box-shadow: -1px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.subNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.expandedNav {
  display: flex;
  width: 35%;
  justify-content: space-between;
  align-items: center;
}

.footer {
  background: whitesmoke;
  width: 100%;
  border-top: 3px solid #964000;
  z-index: 1;
}

.footer div {
  background: whitesmoke;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

.footer div p:hover {
  color: #964000;
}

.hamNav {
  display: none;
  height: 36px;
  width: 36px;
  position: relative;
  transition: .6s;
  transform: rotate(180deg);
}

.leftArrow {
  height: 25px;
  width: 10px;
  background: #964000;
  transform: rotate(45deg);
  position: absolute;
  left: 8px;
  top: 5px;
}

.rightArrow {
  height: 25px;
  width: 10px;
  background: #964000;
  transform: rotate(-45deg);
  position: absolute;
  right: 8px;
  top: 5px;
}

.rotated {
  transform: rotate(0deg)
}

.dropdownNav {
  flex-direction: column;
  align-items: center;
  position: absolute;
  background: whitesmoke;
  top: 65px;
  right: 0;
  padding: 0 25px;
  height: 0px;
  overflow: hidden;
  transition: .6s;
  display: none;
}

.open {
  height: 165px;
  border-bottom: 5px solid #964000;
  padding: 25px;
}

.dropdownNav p {
  margin: 0;
}

.dropdownNav a {
  margin: 15px 0;
  font-size: 25px;
}

.navOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.48);
  z-index: 1;
  transition: .6s
}

.openOver {
  height: 100%;
}

@media (max-width: 825px) {
  .footer div {
    width: 100%;
  }

  .expandedNav {
    display: none;
  }

  .hamNav {
    display: flex;
  }

  .dropdownNav {
    display: flex;
  }

  .navOverlay { 
    display: inherit
  }
}
